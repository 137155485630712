/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  screens: [],
}
export const screenSlice = createSlice({
  name: 'visibleScreenList',
  initialState: { visibleScreenList: initialValue },
  reducers: {
    setScreens: (state, action) => {
      state.visibleScreenList = action.payload
    },
    cleanupScreen: (state) => {
      state.visibleScreenList = initialValue
    },
  },
})

export const { cleanupScreen, setScreens } = screenSlice.actions
export default screenSlice.reducer
