/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  sections_id: [],
}
export const sectionSlice = createSlice({
  name: 'visibleSections',
  initialState: { visibleSections: initialValue },
  reducers: {
    setSectionsIds: (state, action) => {
      state.visibleSections = action.payload
    },
    cleanupSection: (state) => {
      state.visibleSections = initialValue
    },
  },
})

export const { setSectionsIds, cleanupSection } = sectionSlice.actions
export default sectionSlice.reducer
