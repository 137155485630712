/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { modal: false, isDeleted: false }

export const showModalSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    show: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { show } = showModalSlice.actions

export default showModalSlice.reducer
