/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accept_convert: null,
  step: 1,
}

export const convertStudyShiftSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    setAcceptConvert: (state, action) => {
      state.visible = action.payload
    },
    cleanUpConvert: (state) => {
      state.visible = initialState
    },
  },
})

export const { setAcceptConvert } = convertStudyShiftSlice.actions

export default convertStudyShiftSlice.reducer
