/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  ShowTopic: true,
}

export const showTopic = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    setShowTopic: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { setShowTopic } = showTopic.actions
export default showTopic.reducer
