/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = { modal: false }

export const showSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialState },
  reducers: {
    showModal: (state, { payload }) => {
      state.visible = { ...payload }
    },
  },
})

export const { showModal } = showSlice.actions

export default showSlice.reducer
