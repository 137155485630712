/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  AccountantScreens: [],
  Administration: [],
  ExaminationCommittee: [],
  FinanceUnit: [],
  MigrationUnit: [],
  RegistrationDivision: [],
  RegistrationUnit: [],
  Student: [],
  StudentScreens: [],
  Supervision: [],
  TeacherScreens: [],
  ContinuingEducation: [],
}

export const RolesSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialValue },
  reducers: {
    setNavigation: (state, action) => {
      state.visible = action.payload
    },
    cleanupNav: (state) => {
      state.visible = initialValue
    },
  },
})

export const { setNavigation, cleanupNav } = RolesSlice.actions
export default RolesSlice.reducer
