/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  settingList: [],
  isSubmitted: false,
  msgShown: false,
  msg: '',
  screens: [],
}
export const settingSlice = createSlice({
  name: 'visibleSettingList',
  initialState: { visibleSettingList: initialValue },
  reducers: {
    setSettingListRedux: (state, action) => {
      state.visibleSettingList = action.payload
    },
    cleanupSetting: (state) => {
      state.visibleSettingList = initialValue
    },
    setScreens: (state, action) => {
      state.visibleSettingList = action.payload
    },
  },
})

export const { setSettingListRedux, cleanupSetting, setScreens } = settingSlice.actions
export default settingSlice.reducer
