import { combineReducers } from '@reduxjs/toolkit'

import setReducer from 'src/redux/Reducer/sidebar/setReducer'
import showReducer from './redux/Reducer/ModalReducer/showSlice'
import showModalReducer from './redux/Reducer/ModalReducer/showModalSlice'
import dataForEditRoleFlagReducer from './redux/Reducer/StorageDataForEditRoleReducer/StorageDataForEditRoleReducerFlagSlice'
import userTypeReducer from './redux/Reducer/usertype/UserTypeSlice'
import navigationReducer from './redux/Reducer/RolesReducer/RolesSlice'
import tableReduce from './redux/Reducer/tableReducer/TableSlice'
import convertStudyShiftSlice from './redux/Reducer/convertStudyShift/convertStudyShiftSlice'
import showTopic from './redux/Reducer/showTopic/showTopic'
import settingSlice from './redux/Reducer/settingReducer/settingSlice'
import sectionSlice from './redux/Reducer/sections/sectionSlice'
import tokenSlice from './redux/Reducer/token/tokenSlice'
import screenSlice from './redux/Reducer/screenReducer/screenSlice'

const storeRoutes = combineReducers({
  sidebar: setReducer,
  showModal: showReducer,
  modal: showModalReducer,
  dataFlag: dataForEditRoleFlagReducer,
  user: userTypeReducer,
  nav: navigationReducer,
  table: tableReduce,
  convertStudyShift: convertStudyShiftSlice,
  showTopic: showTopic,
  settingList: settingSlice,
  sectionIds: sectionSlice,
  tokenModal: tokenSlice,
  screenList: screenSlice,
})

export default storeRoutes
