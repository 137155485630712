/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  usertype: null,
}

export const UserTypeSlice = createSlice({
  name: 'usertype',
  initialState: { usertype: initialState },
  reducers: {
    setUser: (state, action) => {
      state.usertype = action.payload
    },
  },
})

export const { setUser } = UserTypeSlice.actions

export default UserTypeSlice.reducer
