/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit'

const initialValue = {
  AllRow: [],

}

export const RolesSlice = createSlice({
  name: 'visible',
  initialState: { visible: initialValue },
  reducers: {
    setRow: (state, action) => {
      state.visible = action.payload
    },
    cleanUpTable: (state) =>{
      state.visible = initialValue
    }
  },
})

export const { setRow, cleanUpTable } = RolesSlice.actions
export default RolesSlice.reducer
